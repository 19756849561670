@media screen and (max-width: 780px) {
    .hideonmobile {
        display: none;
    }

    
  }

  @media screen and (max-width: 768px) {
    .onMobileView {
        display: block;
    }

    
  }
  
  /* Tablet */
  @media (min-width: 770px) and (max-width: 1024px){
    .onMobileView {
        display: none;
    }
}

/* Deskptop */
@media (min-width: 1025px) and (max-width: 1280px){
    .onMobileView {
        display: none;
    }
}

    /* Media Query for Large screens */
    @media (min-width: 1281px) {
        .onMobileView {
            display: none;
        }
    }
 