.chatbox-container {
  /* position: relative; */
  background-color: rgba(0, 0, 0, 0.301);
  width: 100vw;
  height: 120vh;
  left: 0;
  top: 0;
  position: fixed;
  z-index: 3;
  transition: all 300ms ease;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}

.chatbox-main {
  width: 500px;
  height: calc(100vh - 70px);
  position: fixed;
  top: 70px;
  right: 0;
  background: #f6fbfd;
  z-index: 12;
  transition: all 400ms ease;
  /* padding-top: ; */
}

.hideChatBox {
  transform: translateX(500px);
  transition: all 400ms ease;
}

.hideChatBoxOverlay {
  transform: translateX(100vw);
  background-color: transparent;
  transition: all 500ms ease;
  /* position: relative;
    width: 0; */
}

.chatbox-main-inner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.chatboxHeader {
  width: 100%;
  height: 12%;
  background: linear-gradient(90deg, #82cff0 0%, #1997cf 100%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
}

.chatboxHeader .chatboxHeaderCol1 {
  display: flex;
  padding-top: 10px;
}

.chatboxHeader .chatboxHeaderCol1 p {
  margin: 0;
  padding: 0;
  font-weight: bold;
  font-size: 15px;
  color: #ffffff;
}

.chatboxHeader .chatboxHeaderCol1 svg {
  margin-right: 10px;
}

.chatBoxBody {
  width: 100%;
  height: 73%;
  padding-left: 20px;
  padding-right: 20px;
  overflow-y: scroll;
  padding-top: 20px;
}

.chatBoxInputBox {
  width: 100%;
  height: 15%;
  background-color: white;
  border-top: 1px solid rgba(128, 128, 128, 0.452);
}

.chatBoxInputBox form {
  width: 100%;
  height: 100%;
  border: none;
  display: flex;
}

.chatBoxInputBox form textarea {
  width: 75%;
  height: 100%;
  outline: none;
  padding: 10px;
  font-size: 13px;
  border: none;
  /* box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15); */
  padding-bottom: 30px;
  padding-top: 10px;
  color: rgba(14, 86, 118, 0.4);
}

.chatBoxInputBox form .chatBoxActionSend {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 10px;
}

.chatBoxInputBox form .chatBoxActionSend button {
  height: 44px;
  border-radius: 8px;
  border: none;
  color: white;
  margin-left: 10px;
  width: 68px;
  height: 44px;
  background: #1997cf;
  border-radius: 8px;
  border: none;
  color: white;
  margin-left: 10px;
  padding: 10px;
  flex: 1;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.cursor {
  cursor: pointer !important;
}

.botDefaultMsg {
  display: flex;
  margin-bottom: 10px;
}

.botDefaultMsgCol2 {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  padding-left: 0;
}

.askNelloOptions {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.askNelloOptionsGrid {
  border: 1px solid #def2fb;
  border-radius: 8px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  cursor: pointer;
  transition: all 300ms ease;
  padding-bottom: 5px;
}

.askNelloOptionsGrid:hover {
  border: 1px solid #1998cf80;
}

.askNelloOptionsGrid img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
}

.askNelloOptionsGrid p {
  color: #1997cf;
  font-size: 12px;
  padding-top: 10px;
}

.botDefaultMsgCol2Header p {
  color: #0e5676;
  font-size: 14px;
}

.botDefaultMsgCol1 {
  background-color: #0e577611;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.botDefaultMsgCol1 img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.incomingMsgWrap {
  background: #ffffff;
  box-shadow: 0px 20px 100px rgba(11, 68, 93, 0.04);
  border-radius: 0px 30px 30px 30px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 7px;
  margin-top: 7px;
  transition: all 300ms ease;
  padding: 20px;
  overflow-x: scroll;
  /* padding-left: 4px;
  padding-right: 4px; */
}

.ongoingMsgReplyText {
  background: #1997cf;
  box-shadow: 0px 20px 100px rgba(25, 151, 207, 0.04);
  border-radius: 20px 20px 0px 20px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 10px;
  padding-right: 20px;
  padding-top: 20px;
  margin-bottom: 7px;
  margin-top: 7px;
  transition: all 300ms ease;
}

.incomingMsgWrapMain {
  width: 100%;
}

.ongoingMsgReplyText p {
  color: white;
  font-size: 13px;
}

.incomingMsgWrap {
  display: flex;
  flex-direction: column;
}

.incomingMsgWrap button {
  width: 100px;
  /* flex: none; */
  display: inline;
  /* padding: 15px; */
}

.incomingMsgWrap .incomingMsgWrapMain p {
  color: #0e5676;
  font-size: 13px;
  display: block;
  width: 100%;
  margin-bottom: 5px;
}

.incomingMsgWrap .incomingMsgWrapMain p span {
  color: #1997cf;
}

.btnWrapActionMsg {
  display: flex;
}

.docmsgIntrsjdjWrapActionMsg {
  display: flex;
  flex-wrap: wrap;
}
.docmsgIntrsjdjWrapActionMsg .docmsgIntrsjdj {
  background-color: #1997cf;
  color: white;
  padding: 6px;
  margin-right: 10px;
  margin-bottom: 10px;
  border-radius: 10px;
  font-size: 12px;
  cursor: pointer;
}
.btnWrapActionMsg select,
input {
  background-color: white;
  color: #1997cf;
  color: #1997cf;
  border: 1px solid #1997cf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 6px;
}

.rc-time-picker-input {
  background-color: white !important;
  color: #1997cf !important;
  color: #1997cf !important;
  border: 1px solid #1997cf !important;
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
  background-color: transparent !important;
  font-size: 13px !important;
  margin-right: 10px !important;
  padding: 7px !important;
  border-radius: 6px !important;
}

.redatWrsp .react-datepicker__input-container input {
  background-color: white;
  color: #1997cf;
  color: #1997cf;
  border: 1px solid #1997cf !important;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 6px;
}
.test {
  border: 1px solid red;
  -webkit-animation: showmap 2s ease 400ms;
  animation: showmap 2s ease 400ms;
}

@-webkit-keyframes showmap {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@keyframes showmap {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.btnWrapActionMsg button {
  color: #1997cf;
  border: 1px solid #1997cf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 6px;
}

.selectWrapActionMsg select {
  color: #1997cf;
  border: 1px solid #1997cf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 6px;
  outline: none;
}

.ongoingMsgReplyText button {
  color: #1997cf;
  border: 1px solid #1997cf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  margin-right: 10px;
  padding: 7px;
  border-radius: 6px;
}

.ongoingImgImg {
  margin-right: 0;
  margin-left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.msgBotUserReply {
  display: flex;
}

.ongoingMsgReply {
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.radioBtnWrapDoc {
  font-size: 12px;
  margin-bottom: 5px;
  color: #1997cf;
  display: flex;
}
.radioBtnWrapDoc input {
  cursor: pointer;
  margin-right: 5px;
  margin-top: 5px;
}

.speaclistDisplay {
  display: flex;
}

.speaclistDisplayDoc {
  width: 225px;
  height: 275px;
  background: #ffffff;
  border: 1px solid rgba(9, 58, 79, 0.1);
  box-sizing: border-box;
  border-radius: 8px;
  flex: none;
  padding: 5px;
  padding-bottom: 20px;
  margin-right: 18px;
}

.docImgbottt {
  width: 100%;
  height: 120px;
  border-radius: 8px;
}

.docImgbottt img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.docImgbottname {
  display: flex;
  padding-top: 10px;
  align-items: center;
  justify-content: space-between;
  padding-left: 10px;
  padding-right: 10px;
}

.docImgbottname p {
  margin: 0;
  padding: 0;
  color: rgba(9, 58, 79, 0.85);
  font-weight: 600;
  font-size: 12.5px;
  margin-right: 10px;
  line-height: 16.5px;
}

.docImgbottname button {
  font-size: 12px;
  border: none;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 5px;
  background: #27ae60;
  border-radius: 4px;
  color: white;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.whatDocjs p {
  color: rgba(9, 58, 79, 0.65);
  margin: 0;
  padding: 0;
  margin-top: 5px;
  font-size: 15px;
}

.whatDocjs {
  padding-left: 10px;
  padding-right: 10px;
}

.whatDocjs .docratidn img {
  margin-right: 5px;
  width: 11.4px;
}

.spabdbtns button {
  color: #1997cf;
  border: 1px solid #1997cf;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: transparent;
  font-size: 13px;
  /* margin-right: 10px; */
  padding: 3px 8px 3px 8px;
  font-size: 12px;
  border-radius: 6px;
}

.spabdbtns {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.speaclistDisplay .react-calendar-datetime-picker {
  position: relative !important;
  bottom: 100px;
}
.drugbotdis {
  align-items: flex-start;
}
.drugbotdis p:nth-of-type(2) {
  color: #1997cf;
}

.btnWrapActionMsgCheckBoxCol1 {
  display: flex;
  align-items: center;
}

.btnWrapActionMsgCheckBoxCol1 p {
  margin: 0;
  margin-top: 5px;
  font-size: 14px;
}

.btnWrapActionMsgCheckBoxCol1 input {
  margin: 0;
  width: 15px;
  height: 15px;
  margin-right: 6px;
  cursor: pointer;
}

.btnWrapActionMsgCheckBoxCol2 span {
  margin: 0;
  color: #1997cf;
  font-size: 13px;
  padding: 0;
}

.checkoutStage {
  display: flex;
  position: relative;
}

.stageCol1 {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 20px;
}

.circleCrapstageCol1 {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #1997cf;
  display: flex;
  align-items: center;
  justify-content: center;
}

.activeMediChek {
  width: 70%;
  height: 70%;
  border-radius: 50%;
  background-color: #1997cf;
}

.stageCol2 {
  width: 140px;
  height: 1px;
  background-color: #1997cf;
  position: relative;
  /* left: 4px; */
  top: 10px;
  flex: 1;
}

.checkOutWrapMain {
  padding: 0px 20px 20px 20px;
}

.circleCrapstageCol2 {
  font-size: 15px;
  margin-top: 4px;
  color: #0e5676;
}

.billingAddreess {
  margin-left: -20px;
}

.billingAddreessHeader {
  margin: 0px;
  padding: 0px;
  margin-top: 30px;
  border-bottom: 1px solid rgba(11, 68, 93, 0.2);
  margin-bottom: 10px;
}

.billingAddreessHeader h2 {
  font-size: 18px;
  color: #0b445d !important;
  margin: 0;
  margin-bottom: 10px;
}

.shipingInputWrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-bottom: 10px;
}

.shipingInputWrap label {
  color: rgba(9, 58, 79, 0.45);
  font-size: 13px;
  margin: 0;
  padding: 0;
}

.shipingInputWrap input {
  color: rgba(9, 58, 79, 0.45);
  font-size: 13px;
  margin: 0;
  padding: 0;
  background: rgba(14, 86, 118, 0.07);
  border-radius: 8px;
  border: none;
  height: 40px;
  outline: none;
  padding-left: 10px;
}

.checkoutForm {
  height: 100%;
}

.drugTotalBot {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(11, 68, 93, 0.2);
  margin-top: 20px;
  padding-top: 10px;
}

.drugTotalBot .drugTotalBotCol1 p {
  margin: 0;
  font-size: 16px;
}

.drugTotalBot h2 {
  color: #1997cf;
  font-size: 16px;
}

.drugTotalBot .drugTotalBotCol2 button {
  background: #1997cf;
  border-radius: 80px;
  padding: 10px 20px 10px 20px;
  border: none;
  color: white;
  font-size: 15px;
}

.reciverDrugDe * {
  margin: 0;
  padding: 0;
}

.reciverDrugDe h2 {
  color: #0e5676;
  font-size: 14px;
}

.reciverDrugDe p {
  color: rgba(9, 58, 79, 0.65);
  font-size: 13px;
}

.checkoutRadiontab2 {
  margin-top: 20px;
}

.checkoutRadiontab2 * {
  margin: 0;
  padding: 0;
}

.checkoutRadiontab2 .absdbotcol1 {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
}

.checkoutRadiontab2 .absdbotcol1 input {
  margin-right: 10px;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.checkoutRadiontab2 .absdbotcol1 label {
  color: #1997cf;
  font-size: 14px;
}

.absdbotcol2 p {
  color: #0e5676;
  font-size: 13px;
  margin-left: 30px;
}

.curponChkCode * {
  margin: 0;
  padding: 0;
}

.curponChkCode {
  margin-top: 25px;
}
.curponChkCode p {
  color: #0b445d;
  font-size: 14px;
  margin-bottom: 5px;
}

.curponChkCode .CurponFormBot {
  display: flex;
}

.curponChkCode .CurponFormBot input {
  background: rgba(14, 86, 118, 0.02);
  border: 1px solid rgba(11, 68, 93, 0.25);
  box-sizing: border-box;
  border-radius: 7px 0 0px 7px;
  height: 40px;
  border-right: none;
  outline: none;
  padding-left: 10px;
  font-size: 13px;
}

.curponChkCode .CurponFormBot button {
  background: #e99d2c;
  border-radius: 0px 7px 7px 0;
  border: none;
  color: white;
  padding: 0px 15px 0 15px;
  font-size: 14px;
}

.drugPaySum {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.drugPaySum p {
  margin-bottom: 0;
  color: rgba(9, 58, 79, 0.65);
  font-size: 13px;
}

.healthIntrsdk {
  display: flex;
  flex-wrap: wrap;
}

.healthIntrsdk p {
  margin-right: 9px;
}

@media (max-width: 850px) {
  .chatbox-main {
    width: 90%;
  }

  .chatBoxInputBox form textarea {
    /* width: 75%;
    height: 100%;
    outline: none;
    padding: 10px;
    font-size: 13px;
    border: none;
    /* box-shadow: inset 0px 1px 0px rgba(0, 0, 0, 0.15); */
    /* padding-bottom: 30px;
    padding-top: 10px;
    color: rgba(14, 86, 118, 0.4);  */

    margin-bottom: 50px;
    flex:1;

  }

  .msgBotUserReplyRev {
    flex-direction: column;
  }

  .msgBotUserReplyRev .huopbotRes {
    position: relative;
    top: 28px;
    right: 15px;
  }

  .ongoingMsgReplyText {
    position: relative;
    right: -70px;
    top: 41px;
  }

  .ongoingMsgReply .botDefaultMsgCol1 {
    position: relative;
    top: -16px;
    right: -21px;
  }
  .askNelloOptionsGrid {
    padding: 3px;
  }
  .askNelloOptions {
    grid-gap: 4px;
  }
}
