.bmi-images-wrap {
  display: flex;
  margin-top: 20px;
}

.bmiImg {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  margin-right: 30px;
  transition: all 200ms ease;
}

.bmiImg:hover {
  transform: translateY(-10px);
}

.bmiImg:last-child {
  margin-right: 0;
}

.bmiImg img {
  height: 100px;
}

.bmiImg span {
  font-size: 12px;
  white-space: nowrap;
}
