p{
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #122C47;
    font-size: 16px;
}
h1,h2,h3,h4,h5,h6 {
    font-family: 'Lato', sans-serif;
    font-weight: bold;
    color: #122C47;
}

.intro{
    background: #1997cf;
    margin-top: 600px;
}

.introheading{
    color:white;
}

.personalheading{
    font-size: 20px;
    font-weight: bold;
    line-height: 60px;
}

.personalpara{
    font-size:16px;
    font-weight: 500;
}

.buttoncolor{
    background: #1997cf;
   
    color:white;
    font-size:20px;
    
}

.searchedList{
    max-height: 400px;
    overflow-y: auto;
    

    position: relative;
    z-index: 3;
}



.searchItem{
    
    padding:7px;
    font-size:13px;
    
}

.symptomscancel{
    font-size: 13px;
}

.searchItem:hover{
    background-color: rgb(216, 244, 251);
    padding:7px;
    border-radius: 8px;
}

.summarypara{
    font-size: 14px;
    font-weight: 400;
    font-family: 'Lato', sans-serif;
    color: #122C47;
    
}


@media (max-width: 768px) {
    .container-contact1 {
      padding: 90px 80px 88px 80px;
    }


    .allergiesadd{
        width: 40%;
        height:40px;
       
        margin: auto;
        font-size: 30px;
    }

    .drow{
        padding-left: 50px;
    }

    .diagnosisbatch{
        width:70%;
        height: 25px;
        
    }

    input[type="checkbox"] {
        -webkit-transform: scale(1.5);
        -ms-transform: scale(1.5);
        transform: scale(1.5);
        margin-top: 5px;
      }

      .introheading{
        color:white;
        font-size: 17px;
        text-align: left;
    }
      

}

