.stepheading{
    
}

.stepara{
    font-size: 13px;
    
}

.hoverme{
    color: #666666;
}

.hoverme:hover{
    color:white;
}
.headings{
    /* fontWeight:"bold",
    color:"#ff9017", */
    font-weight:bold;
    color:#ff9017;
}
.intro{
    font-size: 42px;
    color: #093a4f;
}

.feedbackheading{
    color:white;
    text-align: center;
    font-weight:bold;
    font-family: Arial, Helvetica, sans-serif;
    
}
.feedbackpara {
    color:white;
    font-size:15px;
    text-align: center;
    font-weight: 500;
}

  /* Mobile Screen  */

  @media screen and (min-width:300px) and (max-width:764px) {

    .headings{
        /* fontWeight:"bold",
        color:"#ff9017", */
        font-weight:bold;
        color:#ff9017;
        font-size:20px;
    }

    .intro{
        font-size: 25px;
        color: #093a4f;
    }


    .feedbackheading{
        color:white;
        text-align: center;
        font-weight:600;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 18px;
        
    }
    .feedbackpara {
        color:white;
        font-size:15px;
        text-align: justify;
        font-weight: 500;
    }
   
  }